<template>
    <div class="page--article">
        <section>
            <div class="w-full container container-l mx-auto">
                <div class="header-article">
                    <figure class="header-article-img">
                        <!-- <img class="object-cover" :src="article.media[0].path" alt="" /> -->
                        <img
                            class="object-cover"
                            src="@/assets/images/recent-work.png"
                            alt=""
                        />
                    </figure>
                    <div class="header-article-head">
                        <p class="title">
                            {{ article.text }}
                        </p>
                        <p class="date">22/10/2012</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container container-l mx-auto">content</div>
        </section>
        <section>
            <div class="related-article">
                <blog-section
                    :aticles="relatedArticles"
                    :title="$t('headings.relatedArticles')"
                />
            </div>
        </section>
    </div>
</template>
<script>
import BlogSection from "../components/BlogSection.vue";
export default {
    data () {
        return {
            relatedArticles: [
                {
                    id: 2,
                    text: "related article test 2",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "2467879"
                },
                {
                    id: 3,
                    text: "related article test 3",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "243379"
                },
                {
                    id: 4,
                    text: "related article test 4",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245479"
                },
                {
                    id: 5,
                    text: "related article test 5",
                    media: [{ path: "@/assets/images/work-card-1.png" }],
                    slug: "245579"
                }
            ],
            article: {
                id: 1,
                text: "how to How to plan your next project",
                media: [{ path: "https://via.placeholder.com/300" }],
                slug: "245879"
            }
        };
    },
    components: {
        BlogSection
    }
};
</script>
